import React from 'react';
import DiscussProject from 'components/parts/discuss-project';
import FeaturedWork from 'components/parts/featured-work';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import 'components/style.scss';

const LayoutIvebi = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "ivebi/cover_image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="big62-section">
      <div className="big62-container">
        <h2 className="title is-7 is-family-secondary">FEATURED WORK</h2>
        <div className="margin-top-3 margin-bottom-5">
          <FeaturedWork />
        </div>
        <div className="margin-top-3 margin-bottom-3">
          <p className="title is-main-title is-family-secondary">
            Let's use a project currently in the final stages of development to showcase how we work with our clients...
            <br />
            <br />
          </p>
          <h1 className="title is-main-title is-family-secondary">
            Introducing <span className="has-text-big62pink">IVEBI</span>.
            <br />
            <br />
          </h1>
          <p className="title is-main-title is-family-secondary">
            An innovative startup, that in 2020 will{' '}
            <span className="has-text-big62pink">transform financial forecasting</span> for businesses big and small.
          </p>
          <br />
          <br />
          <Img className="bordered-image" fluid={data.coverImage.childImageSharp.fluid} alt="cover image" />
        </div>

        <div className="margin-top-1 margin-bottom-3">
          <br />
          <h2 className="title is-7 is-family-secondary">OVERVIEW</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            BIG62 were engaged to <span className="has-text-big62pink">design</span> and{' '}
            <span className="has-text-big62pink">develop</span> an innovative financial forecasting idea into a{' '}
            <span className="has-text-big62pink">web application</span>.
          </p>
          <br />
          <br />
          <p className="is-body-small-text fix-spacing">
            Now, we can't show you the magic sauce of the application (until it's released), but we can show you how we
            approached the project and worked with our client...
          </p>
          <br />
          <br />
          <p className="is-body-small-text fix-spacing">
            We began with extensive <span className="has-text-big62pink">research</span>, spending time with our client
            to learn as much as possible about the problem the product they wanted to solve, their goals, as well as
            analysing the market, technology, and customers.
          </p>
          <br />
          <br />
          <p className="is-body-small-text fix-spacing">
            We recognised our main challenge would be designing an application that simplified a complex problem, whilst
            providing an <span className="has-text-big62pink">intuitive user experience </span> for the customer.
          </p>
        </div>

        <div className="margin-top-1 margin-bottom-3">
          <br />
          <h2 className="title is-7 is-family-secondary">DESIGN</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            We explored many solutions using rough <span className="has-text-big62pink">pen to paper mockups</span>,
            then presenting our strategy to the client for approval. Following which we created polished{' '}
            <span className="has-text-big62pink">digital wireframes</span> for the entire application.
          </p>
          <br />
          <br />
          <p className="is-body-small-text fix-spacing">
            From these wireframes, we drafted a <span className="has-text-big62pink">techscope document</span> outlining
            the development strategy, technology and schedule for the project.
            <br />
            <br />
          </p>
        </div>

        <div className="content margin-top-1 margin-bottom-3">
          <h2 className="title is-7 is-family-secondary">DEVELOPMENT</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            We first created a <span className="has-text-big62pink">prototype</span> to test the core features.
            Following that, we went into <span className="has-text-big62pink">full development</span>.
            <br />
            <br />
          </p>
          <p className="is-body-small-text fix-spacing">
            Which included,
            <br />
            <ul className="is-body-small-text fix-spacing">
              <li>A beautiful and fast sales website developed using Gatsby</li>
              <li>The main front-end web appliction developed using Typescript, React and Redux</li>
              <li>The back-end AWS infrastructure using Amazon Cognito, SNS, Lambda, API Gateway and RDS</li>
              <li>We used both Golang and Typescript for API function code</li>
              <li>
                A full CI/CD system which covers building, testing and deployment of all both front and back end
                components
              </li>
            </ul>
          </p>
          <p className="is-body-small-text fix-spacing">
            <br />
            Along the way, we've been <span className="has-text-big62pink">testing</span> iteratively, and as we
            approach the end of development, we'll be further testing with a wider range of users, and then Ivebi goes
            to market!
          </p>
        </div>

        <div className="margin-top-1 margin-bottom-3">
          <br />
          <h2 className="title is-7 is-family-secondary">BRANDING</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            We also created the branding for the product, commencing with finding a name - team brainstorming sessions
            with the client led us to <span className="has-text-big62pink">IVEBI</span>.
            <br />
            <br />
            We established a <span className="has-text-big62pink">design language</span>, developed{' '}
            <span className="has-text-big62pink">marketing materials</span>, and the{' '}
            <span className="has-text-big62pink">brand messaging</span> going forward.
          </p>
        </div>

        <div className="margin-top-1">
          <br />
          <h2 className="title is-7 is-family-secondary">CLIENTS WE LOVE</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            We <span className="has-text-big62pink">love</span> working with{' '}
            <span className="has-text-big62pink">clients</span> who are excited about creating something amazing. Ivebi
            is a prime example!
          </p>
          <br />
          <br />
          <p className="is-body-small-text fix-spacing">
            If you have a project you'd like to talk to us about{' '}
            <span className="has-text-big62pink">please click YES! below.</span>
          </p>
        </div>
        <br />
        <div className="big62-section" className="margin-top-4">
          <DiscussProject nextUrl="/work/projectlex" />
        </div>
      </div>
    </div>
  );
};

export default LayoutIvebi;
